<template>
  <div class="base-panel-filter__items">
    <div class="base-panel-filter__title">{{ item.title }}</div>
    <div class="base-panel-filter__search">
      <v-text-field
        clearable
        hide-details
        dense
        autofocus
        placeholder="Поиск"
        append-icon="$ToolSearch"
        v-model="search"
        style="font-weight: 500"
      >
      </v-text-field>
    </div>
    <div class="base-panel-filter__wrap" v-if="value[index]">
      <panel-filter-item
        v-for="elem in filteredItems.slice(0, showItems)"
        :key="elem.id"
        :label="elem.value"
        :value="getItemValue(elem)"
        @input="change($event, index)"
        :id="elem.id"
      ></panel-filter-item>
    </div>
    <div
      class="base-panel-filter__more"
      v-if="filteredItems.length > showItems"
      @click="showItems += 100"
      style="cursor: pointer"
    >
      Еще {{ filteredItems.length - showItems }} {{ item.moreText }}
    </div>
    <div
      class="base-panel-filter__more"
      v-else-if="
        !(filteredItems.length > showItems) && filteredItems.length > 5
      "
      @click="showItems = 5"
      style="cursor: pointer"
    >
      Скрыть ({{ filteredItems.length - 5 }})
    </div>

    <div
      v-if="!filteredItems.length"
      class="base-panel-filter__nodata text--disabled"
    >
      Нет данных
    </div>

    <v-divider class="my-4"></v-divider>
  </div>
</template>
<script>
export default {
  components: {
    panelFilterItem: () => import("./panelFilterItem"),
  },
  props: {
    item: {
      type: Object,
      default: () => ({
        title: "Название ",
        items: [],
        moreText: "элементов ",
      }),
    },
    value: { type: Array, default: () => [[], []] },
    index: { type: Number, default: 0 },
  },
  data() {
    return {
      search: null,
      showItems: 5,
    };
  },
  computed: {
    filteredItems() {
      if (!this.search) return this.item.items;
      else
        return this.item.items.filter((e) =>
          e.value.toLowerCase().includes(this.search.toLowerCase())
        );
    },
  },
  methods: {
    getItemValue(elem) {
      return this.value[this.index]?.includes(elem.id);
    },
    change(e, index) {
      const tmp = [...this.value];

      if (e.value) {
        tmp[index] = [...tmp[index], e.id];
      } else {
        tmp[index] = tmp[index].filter((e2) => e2 !== e.id);
      }

      this.$emit("input", tmp);
    },
  },
};
</script>
<style lang="scss">
.base-panel-filter {
  &__wrap {
    max-height: 300px;
    overflow-y: auto;
  }
  &__title {
    color: var(--v-text-base);

    font-size: 14px;

    font-weight: 600;
  }

  &__search {
    margin-bottom: 6px;

    svg {
      color: var(--v-grey-base);
      width: 15px;
      height: 15px;
    }
  }

  &__more {
    font-size: 13px;
  }
}
</style>
